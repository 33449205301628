import React, {
 useEffect, useState
} from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {
 useMediaQuery
} from 'hooks/useMediaQuery';
import IMAGES from 'constants/Images';
import SupportDetails from 'components/SupportDetails';


const Dialog = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 20;
  overflow-y: auto;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  backdrop-filter: blur(4px);
`;

const ModalDialog = styled.div`
  position: relative;
  width: 100%;
  max-height: 92%;
  background: #fff;
  border-radius: 24px 24px 0 0;
  overflow-y: visible;
  transition: opacity 0.2s ease, transform 0.2s ease;
  @media screen and (min-width:1024px) {
     border-radius: 24px;
     max-width: 600px;
     margin:auto;
  }
`;

const ModalContent = styled.div`
  padding: 24px;
  height: 100%;
  background: #fff;
  border-radius: 24px 24px 0 0;
  max-height: calc(92vh);
  overflow-y: auto;

  @media screen and (min-width: 1024px) {
     border-radius: 24px;
  }
`;

const ModalHeader = styled.div`
`;

const ModalBody = styled.div`
`;

const ModalFooter = styled.div`
`;

const CloseButton = styled.img`
  font-size: var(--font-size-32);
  position: absolute;
  top: -16px;
  right: 24px;
  z-index: 2;
  background-color: #fff;
  border-radius: 32px;
  border: 3px solid #fff;
  cursor: pointer;

  @media screen and (min-width: 1024px) {
    top: -12px;
    right: 21px;
  }
`;

const PopupModal = (props) => {
  const {
    close,
    children,
    modalFooter,
    modalHeader,
    shouldShow,
    hideCloseBtn,
    showSupportLinks,
    ...rest
  } = props;

  const isDesktop = useMediaQuery('(min-width:1024px)');
  const [isVisible, setIsVisible] = useState(shouldShow);

  useEffect(() => {
    if (shouldShow) {
      setIsVisible(true);
    } else {
      const timer = setTimeout(() => setIsVisible(false), 200);
      return () => clearTimeout(timer);
    }
  }, [shouldShow]);

  if (!isVisible) return null;

  return (
    <Dialog>
      <ModalDialog
        className={isDesktop ? '' : 'line-up-animation-class'}
        style={{opacity: shouldShow ? 1 : 0, transform: shouldShow ? 'scale(1)' : 'scale(0.5)'}}
      >
        <ModalContent>
          <ModalHeader>
            {modalHeader}
            {hideCloseBtn ? null : (
              <CloseButton
                alt="close"
                width={36}
                height={36}
                src={IMAGES.closeImage}
                onClick={close}
              />
            )}
          </ModalHeader>
          <ModalBody>
            {children}
          </ModalBody>
          <ModalFooter>
            {modalFooter}
            {showSupportLinks ?  <SupportDetails/> : null}
          </ModalFooter>
        </ModalContent>
      </ModalDialog>
    </Dialog>
  );
};

PopupModal.propTypes = {
  close: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  modalFooter: PropTypes.node,
  modalHeader: PropTypes.node,
  shouldShow: PropTypes.bool.isRequired,
  hideCloseBtn: PropTypes.bool,
  showSupportLinks: PropTypes.bool,
};

export default PopupModal;