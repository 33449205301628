import {
 useState
} from 'react';
import PropTypes from 'prop-types';

import {
 useIsomorphicLayoutEffect
} from './useIsomorphicLayoutEffect';

const IS_SERVER = typeof window === 'undefined';

/**
 * Custom hook that tracks the state of a media query using the [`Match Media API`](https://developer.mozilla.org/en-US/docs/Web/API/Window/matchMedia).
 * @param {string} query - The media query to track.
 * @param {Object} options - The options for customizing the behavior of the hook (optional).
 * @param {boolean} [options.defaultValue=false] - The default value to return if the hook is being run on the server.
 * @param {boolean} [options.initializeWithValue=true] - If `true` (default), the hook will initialize reading the media query. In SSR, you should set it to `false`, returning `options.defaultValue` or `false` initially.
 * @returns {boolean} The current state of the media query (true if the query matches, false otherwise).
 * @public
 * @see [Documentation](https://usehooks-ts.com/react-hook/use-media-query)
 * @example
 * ```jsx
 * const isSmallScreen = useMediaQuery('(max-width: 600px)');
 * // Use `isSmallScreen` to conditionally apply styles or logic based on the screen size.
 * ```
 */
export function useMediaQuery(
    query,
    {
        defaultValue = false,
        initializeWithValue = true,
    } = {},
) {
    const getMatches = (query) => {
        if (IS_SERVER) {
            return defaultValue;
        }
        return window.matchMedia(query).matches;
    };

    const [matches, setMatches] = useState(() => {
        if (initializeWithValue) {
            return getMatches(query);
        }
        return defaultValue;
    });

    // Handles the change event of the media query.
    function handleChange() {
        setMatches(getMatches(query));
    }

    useIsomorphicLayoutEffect(() => {
        const matchMedia = window.matchMedia(query);

        // Triggered at the first client-side load and if query changes
        handleChange();

        // Use deprecated `addListener` and `removeListener` to support Safari < 14 (#135)
        if (matchMedia.addListener) {
            matchMedia.addListener(handleChange);
        } else {
            matchMedia.addEventListener('change', handleChange);
        }

        return () => {
            if (matchMedia.removeListener) {
                matchMedia.removeListener(handleChange);
            } else {
                matchMedia.removeEventListener('change', handleChange);
            }
        };
    }, [query]);

    return matches;
}


useMediaQuery.propTypes = {
    query: PropTypes.string.isRequired,
    options: PropTypes.shape({
        defaultValue: PropTypes.bool,
        initializeWithValue: PropTypes.bool
    })
};