import IMAGES from 'constants/Images';
import WealthyConstants from 'constants/wealthyConstants';
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';


function SupportDetails({wrapperClassName}) {
  return (
    <StyledParagraph className={`caption2 ${wrapperClassName}`}>
      <StyledImage
        alt={'support'}
        height={32}
        width={32}
        src={IMAGES.supportIcon}
      />
      You can reach out to us at : chat support in app&apos;s profile section
      or &nbsp;
      <span>
        <StyledLink href={`mailto:${WealthyConstants.SUPPORT_EMAIL_ID}`}>
          {WealthyConstants.SUPPORT_EMAIL_ID}
        </StyledLink>
      </span>{' '}
      or{' '}
      <span>
        <StyledLink href={`tel:${WealthyConstants.SUPPORT_MOBILE_NUMBER}`}>
          {WealthyConstants.SUPPORT_MOBILE_NUMBER}
        </StyledLink>
      </span>
    </StyledParagraph>
  );
}

SupportDetails.propTypes = {
  wrapperClassName: PropTypes.string,
};

SupportDetails.defaultProps = {
  wrapperClassName: '',
};

export default SupportDetails;


const StyledParagraph = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 19.6px;
  text-align: center;

`;

const StyledImage = styled.img`
  margin-right: 12px;
`;

const StyledLink = styled.a`
  color: #6725f4;
  font-size: 14px;
  font-weight: 400;
  line-height: 19.6px;
  text-align: center;
`;