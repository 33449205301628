/**
Author - Brijesh Pandey
Git - https://bitbucket.org/__brijesh/
**/

const kycScreenMapping = {
  SELFIE: "SELFIE",
  FACE_MATCH: "FACE_MATCH",
  E_SIGN: "E_SIGN",
  DDPI: "DDPI",
  SPECIMEN_SIGN: "SPECIMEN_SIGN",
  DIGILOCKER: "DIGILOCKER",
  PAN_STRICT_VALIDATION: "PAN_STRICT_VALIDATION",
  NOMINEE: "NOMINEE",
  PENNY_DROP: "PENNY_DROP",
  PERSONAL_DETAILS: "PERSONAL_DETAILS",
  COMPLETE: "COMPLETE",
  INCOME_DECLARATION: "INCOME_DECLARATION",
  CHOICE: "CHOICE",
  UPLOAD_PAN: "UPLOAD_PAN",
  CHECK_ARN: "CHECK_ARN",
  META_FORM: "META_FORM",
  AGREEMENT: "AGREEMENT",
  GST_DETAILS: "GST_DETAILS",
  BANK_PROOF:"BANK_PROOF",
  ADDRESS:"ADDRESS",
  ADDRESS_PROOF:"ADDRESS_PROOF",
  OPS_EVALUATION:'OPS_EVALUATION',
  PASSPORT:'PASSPORT',
  TRADING_PREFERENCES:'TRADING_PREFERENCES',
  UPLOAD_ARN: 'UPLOAD_ARN',
  UPLOAD_EUIN: 'UPLOAD_EUIN',
  ARN_CHOICE: "ARN_CHOICE",
  IDS_CHOICE: "IDS_CHOICE",
  UPLOAD_IDS: "UPLOAD_IDS",
  FATCA:"FATCA",
  RPD:"RPD"
};

const KycFlowMapping = {
  MF_NRI: "MF_NRI",
  PARTNER: "PARTNER"
};

export default kycScreenMapping;
export {
KycFlowMapping
};