
export const appendQueryParamToUrl = (
    currentUrl: string,
    paramName: string,
    value: string
) => {
    
    if (!currentUrl ) return "";

    const url = new URL(currentUrl);
    const existingSearchParams = url.searchParams.toString();
    const finalUrl = `${url.origin}${url.pathname}?${existingSearchParams
            ? `${existingSearchParams}&${paramName}=${value}`
            : `&${paramName}=${value}`
        }`;

    return finalUrl;
};
export const appendMultipleQueryParamsToUrl = (
    currentUrl: string,
    params: { paramName: string; value: string }[]
) => {
    if (!currentUrl) return "";
    
    let finalUrl = currentUrl;
    params.forEach((pd) => {
        finalUrl = appendQueryParamToUrl(finalUrl, pd.paramName, pd.value);
    });

    return finalUrl;
};