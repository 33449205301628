import {
 getMaxDateOfBirthFor18YearsOld
} from "utils/dateUtils";

export const nomineeRelations = [
    {
        label: "Spouse",
        value: "Spouse"
    },
    {
        label: "Son",
        value: "Son"
    },
    {
        label: "Daughter",
        value: "Daughter"
    },
    {
        label: "Father",
        value: "Father"
    },
    {
        label: "Mother",
        value: "Mother"
    },
    {
        label: "Brother",
        value: "Brother"
    },
    {
        label: "Sister",
        value: "Sister"
    },
    {
        label: "Grand Son",
        value: "Grand Son"
    },
    {
        label: "Grand Daughter",
        value: "Grand Daughter"
    },
    {
        label: "Grand Father",
        value: "Grand Father"
    },
    {
        label: "Grand Mother",
        value: "Grand Mother"
    },
    {
        label: "Others",
        value: "Others"
    },
];

export const getNomineeSchema = () => {
    const todayDate = new Date().toISOString().split('T')[0];

    const majorDob = getMaxDateOfBirthFor18YearsOld();

    return {
        theme: {
            type: 'mui',
            mui: {
                "size": "medium",
                "variant": "standard"
            },
            className: 'basic-form',
        },
        fields: [
            {
                name: 'name',
                meta: {
                    displayName: 'Nominee Full Name',
                    displayType: 'text',
                     className: "input-container",
                    displayProps: {
                        sm: 12,
                        md: 6,
                    },
                    validation: {
                        required: true,
                        pattern: "^[A-Za-z]+(?:\\s?[A-Za-z']+)*$",
                        patternDetail: {
                            errorMsg:
                                'Special characters, numbers or multiple spaces are not allowed',
                        },
                    },
                },
            },
            {
                name: 'dob',
                meta: {
                    displayName: 'Nominee Date of Birth',
                    displayType: 'date',
                     className: "input-container",
                    placeholder: 'DD/MM/YYYY',
                    displayProps: {
                        sm: 12,
                        md: 6,
                    },
                    validation: {
                        required: true,
                        max: todayDate,
                    },
                },
            },
            {
                name: 'relation',
                meta: {
                    displayName: 'Your Relation With Nominee',
                    displayType: 'select',
                    className: "input-container",
                    displayProps: {
                        sm: 12,
                    },
                    options: nomineeRelations,
                    validation: {
                        required: true,
                    },
                },
            },
            {
                name: 'nomineeIdSelector',
                meta: {
                    displayName: 'Choose Nominee ID',
                    displayType: 'radio',
                    className: "input-container nominee-form-id-selector-field",
                    value: 'AADHAAR',
                    options: [
                        {
                            label: 'Aadhaar Number',
                            value: 'AADHAAR',
                        },
                        {
                            label: 'PAN Number',
                            value: 'PAN',
                        },
                    ],
                    displayProps: {
                        sm: 12,
                    },
                    validation: {
                        required: true,
                    },
                },
            },
            {
                name: 'pan_number',
                meta: {
                    displayName: 'Enter Nominee PAN number',
                    displayType: 'text',
                    className: "input-container",
                    displayProps: {
                        sm: 12,
                    },
                    value: '',
                    dependencies: {
                        exists: {
                            ref: 'nomineeIdSelector',
                            value: 'PAN',
                        },
                    },
                    validation: {
                        required: true,
                    },
                },
            },
            {
                name: 'aadhaar_number',
                meta: {
                    displayName: "Last 4 digit's of Nominee Aadhaar",
                    displayType: 'number',
                    className: "input-container",
                    displayProps: {
                        sm: 12,
                    },
                    value: '',
                    validation: {
                        required: true,
                        pattern: '^[0-9]{4}$',
                        patternDetail: {
                            errorMsg: 'Please enter exactly last 4 digits of Aadhaar number',
                        },
                    },
                    dependencies: {
                        exists: {
                            ref: 'nomineeIdSelector',
                            value: 'AADHAAR',
                        },
                    },
                },
            },
            {
                name: 'guardian-info-text',
                meta: {
                    displayName:
                        'As nominee is a Below 18yrs, Legal Guardian is required',
                    displayType: 'paragraph',
                    className: "input-container guardian-info-text",
                    displayProps: {
                        md: 12,
                    },
                    dependencies: {
                        exists: {
                            ref: 'dob',
                            value: '$valueFn',
                            valueFn: 'isMinor',
                        },
                    },
                },
            },
            {
                name: 'guardian_name',
                meta: {
                    displayName: 'Guardian Name',
                    displayType: 'text',
                    className: "input-container",
                    displayProps: {
                        md: 6,
                    },
                    validation: {
                        required: true,
                        pattern: "^[A-Za-z]+(?:\\s?[A-Za-z']+)*$",
                        patternDetail: {
                            errorMsg:
                                'Special characters, numbers or multiple spaces are not allowed',
                        },
                    },
                    dependencies: {
                        exists: {
                            ref: 'dob',
                            value: '$valueFn',
                            valueFn: 'isMinor',
                        },
                    },
                },
            },
            {
                name: 'guardian_dob',
                meta: {
                    displayName: 'Guardian Date of birth',
                    displayType: 'date',
                    className: "input-container",
                    displayProps: {
                        md: 6,
                    },
                    validation: {
                        required: true,
                        max: majorDob,
                    },
                    dependencies: {
                        exists: {
                            ref: 'dob',
                            value: '$valueFn',
                            valueFn: 'isMinor',
                        },
                    },
                },
            },
            {
                name: 'guardian_pan_number',
                meta: {
                    displayName: 'Enter Guardian PAN number',
                    displayType: 'text',
                    className: "input-container",
                    displayProps: {
                        sm: 12,
                    },
                    value: '',
                    validation: {required: true},
                    dependencies: {
                        exists: {
                            ref: 'dob',
                            value: '$valueFn',
                            valueFn: 'isMinor',
                        },
                    },
                },
            },
            {
                name: 'add_guardian_custom',
                prop: null,
                meta: {
                    displayType: 'custom_is_minor_block',
                     className: "input-container",
                    displayName: 'Add Guardian',
                    value: false,
                    dependencies: {
                        exists: {
                            ref: 'dob',
                            value: '$valueFn',
                            valueFn: 'isMinor',
                        },
                    },
                },
            },
        ],
        buttons: [
            {
                name: 'Add',
                meta: {
                    type: 'submit',
                    displayName: 'Next',
                    className: 'col',
                },
            },
        ],
    };
};
