export const appendZero = (num: number) => num.toString().length < 2 ? `0${num.toString()}` : num.toString();

export const encodeDateFormat = (d: any) => new Date(
        d.split('/')[2],
        d.split('/')[1] - 1,
        d.split('/')[0],
        12
    ).toISOString();

export const decodeDateFormat = (d: any) => {
    if (!d) return;
    const date = new Date(d);
    return `${appendZero(date.getDate())}/${appendZero(
        date.getMonth() + 1
    )}/${appendZero(date.getFullYear())}`;
};

export const decodeDateFormatWithHyphen = (d: any) => {
    if (!d) return;
    const date = new Date(d);
    return `${appendZero(date.getFullYear())}-${appendZero(
        date.getMonth() + 1
    )}-${appendZero(date.getDate())}`;
};

export const getAgeFromDOB = (dob: string) => {
    const birthDate = new Date(dob);
    const currentDate = new Date();
    let age = currentDate.getFullYear() - birthDate.getFullYear();
    const monthGap = currentDate.getMonth() - birthDate.getMonth();
    if (
        monthGap < 0 ||
        (monthGap === 0 && currentDate.getDate() < birthDate.getDate())
    ) {
        age--;
    }
    return age;
};

export const isMinor = (dob: string) => {
    const age = getAgeFromDOB(dob);
    return age < 18;
};

export const getDateStringFromDatePicker = (date: Date): string => {
    const offset = date.getTimezoneOffset() * 60000;
    const dateWithOffset = new Date(date.getTime() - offset);
    return dateWithOffset.toISOString().substring(0, 10);
};

// returns date in DD/MM/YYYY
export const getDateInIndianFormat = (date: Date): string => {
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return `${day < 10 ? `0${day}` : day}/${month < 10 ? `0${month}` : month
        }/${year}`;
};

export const isEpochDate = (date: any) => {
    if (!date) return false;
    const d = new Date(date);
    const EPOCH_DATE = new Date('1970-01-01');

    return (
        d.getFullYear() === EPOCH_DATE.getFullYear() &&
        d.getMonth() === EPOCH_DATE.getMonth() &&
        d.getDate() === EPOCH_DATE.getDate()
    );
};

/**
 * Converts a date string in the format 'YYYY-MM-DD' to long date format [dd Month yyyy].
 * @param {string} dateString - The date string in 'YYYY-MM-DD' format.
 * @returns {string} The long date format string, e.g., '20 November 2033'.
 */
export const getLongDateFormat = (dateString = ''): string => {
    if (!dateString) return '';
    const months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ];

    try {
        const [year, month, day] = dateString.split('-');
        const monthName = months[parseInt(month) - 1];

        return `${parseInt(day)} ${monthName} ${year}`;
    } catch (error) {
        console.error('error:', error);
        return '';
    }
};

/**
 * Converts a number into its corresponding ordinal form with the appropriate suffix ("st", "nd", "rd", or "th").
 * @param {number} number - The number to convert to ordinal form.
 * @returns {string} The ordinal representation of the input number.
 * @example
 * // Returns "1st"
 * getOrdinal(1);
 * @example
 * // Returns "92nd"
 * getOrdinal(92);
 */
export const getOrdinal = (number: number): string => {
    if (number === 0) return `${number}`; // 0th is irregular, return 0

    const suffixes = ['th', 'st', 'nd', 'rd', 'th'];
    const lastDigit = number % 10;
    const lastTwoDigits = number % 100;

    // Special cases for 11th, 12th, and 13th
    if (lastTwoDigits >= 11 && lastTwoDigits <= 13) {
        return number + 'th';
    } else {
        return number + suffixes[Math.min(lastDigit, 4)];
    }
};

//To solve: https://stackoverflow.com/questions/7556591/is-the-javascript-date-object-always-one-day-off
/**
 * Removes the default time zone conversion(UTC) when we replace '-' with '/'
 * @param {dateStr}  - date string in format yyyy-mm-dd
 * @returns {string} - in the format of yyyy/mm/dd
 */
export const getLooseFormatOfDate = (dateStr: string) => {
    if (dateStr) return dateStr?.replace(/-/g, '/');
    return '';
};

/**
 * Removes the time zone and replace '-' with '/'
 * @param {dateStr}  - date string in format yyyy-mm-ddT00:00:00+00:00
 * @returns {string} - in the format of yyyy/mm/dd
 */
export const removeTimeZoneAndGetLooseFormat = (dateWithTimeZone: string) => {
    if (!dateWithTimeZone) return '';
    const dateString = dateWithTimeZone.split('T')[0];
    return getLooseFormatOfDate(dateString);
};

//Input format yyyy-mm-dd
// Output format is dd-mm-yyy
export const dayMonthYearFormatInSequence = (dateString: string) => {
    if (!dateString) {
        return '';
    }

    const dateParts = dateString.split('-');

    if (dateParts.length !== 3) {
        console.error('Invalid Date format for conversion');
        return '';
    }

    return `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`;
};

/**
 * Calculates the maximum date of birth for someone who is at least 18 years old.
 * @returns {string} The date in 'YYYY-MM-DD' format.
 */
export const getMaxDateOfBirthFor18YearsOld = (): string => {
    const today = new Date();
    const majorDateOfBirth = new Date(
        today.getFullYear() - 18,
        today.getMonth(),
        today.getDate()
    );

    return decodeDateFormatWithHyphen(majorDateOfBirth) ?? '';
};

/**
 * Converts a date string to the format "dd/mm/yyyy".
 * @param {string} dateString - The input date string (can be in various formats).
 * @returns {string} The date in "dd/mm/yyyy" format, or an empty string if invalid.
 */
export const convertToDateFormat = (dateString: string): string => {
    if (!dateString) return '';

    const date = new Date(dateString);
    if (isNaN(date.getTime())) return ''; // Invalid date

    const day = appendZero(date.getDate());
    const month = appendZero(date.getMonth() + 1);
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
};
