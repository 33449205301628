/**
Author - Brijesh Pandey
Git - https://bitbucket.org/__brijesh/
**/

import React from "react";

import styled from "styled-components";

import NomineeMetaForm from "./components";

const NomineeDetails = ({
  onChangeHandler,
  checkIsMinor,
  onSubmit,
  formData,
  isLoading,
  isDisabled,
  isMinor,
  nomineeCount,
  nomineeCountChangeHandler,
  relationDropdownContent,
  relationDescription,
  setRelationDescription
}) => (
    <NomineeDetailsWrapper>
    <NomineeMetaForm onSubmit={onSubmit} isLoading={isLoading} isDisabled={isDisabled}/>
    </NomineeDetailsWrapper>
  );

const NomineeDetailsWrapper = styled.div`
  /* background: #FFF; */
  height: 100vh;
  width: 100%;
  scroll-behavior: scroll;
  position: relative;
  overflow-y: scroll;

  @media (max-width: 768px) {
    background:#fff;
  }
`;


export default NomineeDetails;
