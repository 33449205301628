/**
Author - Brijesh Pandey
Git - https://bitbucket.org/__brijesh/
**/

let env;

switch (process.env.REACT_APP_STAGE) {
  case "development":
    env = {
      REST_DOMAIN_URL: "https://kycapi.wealthydev.in",
      GRAPH_URL: "https://graph.buildwealthdev.in/graphql/",
      WEALTHY_WEBSITE_URL: "https://www.wealthydev.in",
      VALKYRIE_URL: 'https://wealthydev.in/client/profile',
      WEALTHY_CDN_URL: "https://i.wlycdn.com",
      WEALTHY_KYC_URL: "http://kyc.wealthydev.in",
      RAZORPAY_URL: "https://ifsc.razorpay.com/",
      RUDDERSTACK_WRITE_KEY: "2PvHllop7Dh74cNqOPzRjPF6tut",
      PARTNER_DASHBOARD_URL: "https://app.buildwealthdev.in",
      APP_LINK_URL: "https://applinks.buildwealth.in",
      MS_CLARITY_CODE: "oyf6dzcp5l"
    };
    break;

  case "production":
    env = {
      REST_DOMAIN_URL: "https://kycapi.wealthy.in",
      GRAPH_URL: "https://graph.buildwealth.in/graphql/",
      WEALTHY_WEBSITE_URL: "https://www.wealthy.in",
      WEALTHY_CDN_URL: "https://i.wlycdn.com",
      WEALTHY_KYC_URL: "https://kyc.wealthy.in",
      RAZORPAY_URL: "https://ifsc.razorpay.com/",
      IS_PRODUCTION: true,
      RUDDERSTACK_WRITE_KEY: "2PvGzH3SmS4AlTtOw8cTDqaISHX",
      PARTNER_DASHBOARD_URL: "https://app.buildwealth.in",
      VALKYRIE_URL: 'https://wealthy.in/client/profile',
      APP_LINK_URL: "https://applinks.buildwealth.in",
      MS_CLARITY_CODE: "oyesczr1va"
    };
    break;

  default:
    env = {
      REST_DOMAIN_URL: "https://kycapi.wealthydev.in",
      GRAPH_URL: "https://graph.buildwealthdev.in/graphql/",
      WEALTHY_WEBSITE_URL: "https://www.wealthydev.in",
      WEALTHY_CDN_URL: "https://i.wlycdn.com",
      WEALTHY_KYC_URL: "http://localhost:3000",
      RAZORPAY_URL: "https://ifsc.razorpay.com/",
      IS_LOCAL: true,
      RUDDERSTACK_WRITE_KEY: "2PvHllop7Dh74cNqOPzRjPF6tut",
      PARTNER_DASHBOARD_URL: "https://app.buildwealthdev.in",
      APP_LINK_URL: "https://applinks.buildwealth.in",
      VALKYRIE_URL: 'https://wealthydev.in/client/profile',
      MS_CLARITY_CODE: "oyf6dzcp5l"
    };
}

export default env;
