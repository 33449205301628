/**
Author - Brijesh Pandey
Git - https://bitbucket.org/__brijesh/
**/

import React, {
useState, useEffect
} from "react";
import dateFormat from "dateformat";

import {
  updateNomineeDetails
} from "api";
import {
  WealthyValidations,
  triggerToast
} from "helpers";
import {
 isMinor
} from "utils/dateUtils";
import  handleApiError from "helpers/handleApiError";

import NomineeDetails from "./NomineeDetails";

const relationDropdownOptions = [
  {
    label: "Spouse",
    value: "Spouse"
  },
  {
    label: "Son",
    value: "Son"
  },
  {
    label: "Daughter",
    value: "Daughter"
  },
  {
    label: "Father",
    value: "Father"
  },
  {
    label: "Mother",
    value: "Mother"
  },
  {
    label: "Brother",
    value: "Brother"
  },
  {
    label: "Sister",
    value: "Sister"
  },
  {
    label: "Grand Son",
    value: "Grand Son"
  },
  {
    label: "Grand Daughter",
    value: "Grand Daughter"
  },
  {
    label: "Grand Father",
    value: "Grand Father"
  },
  {
    label: "Grand Mother",
    value: "Grand Mother"
  },
  {
    label: "Others",
    value: "Others"
  },
];

export const MAJOR_ONLY_NOMINEE_RELATIONS = ['Spouse', 'Father', 'Mother', 'Grand Father', 'Grand Mother'];

const NomineeDetailsContainer = ({
  kycData,
  updateStatus
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const validateFields = (data) => {
    const validatedName = WealthyValidations.validateFormField.name(data.name);
    const validatedPan = WealthyValidations.validateFormField.panGeneric(data?.panNumber?.toUpperCase());
    const isNomineeMinor = isMinor(data.dob);
    const relation = data.relation;

    if (isNomineeMinor) {
      
      if (MAJOR_ONLY_NOMINEE_RELATIONS.includes(relation)) {
        const relationName = relationDropdownOptions.find(
          (n) => n.value === relation
        )?.label;
        triggerToast(
          `${relationName} relation is not allowed for Minor date of birth`,
          "error"
        );
        return;
      } else if (data.nomineeIdSelector === 'PAN') {
        triggerToast(
          `Minor nominees must provide Aadhaar number (last 4 digits) as Nominee ID`,'error'
        );
        return;
      }


      const validatedGuardianPan = WealthyValidations.validateFormField.panGeneric(data?.guardian_pan_number?.toUpperCase());
      if (!validatedGuardianPan) {
        triggerToast("Guardian Pan is invalid");
        return false;
      }

    }
    if(!validatedName) {
      triggerToast("Name can only contain alphabets");
      return false;
    };

    if (!isNomineeMinor && !validatedPan && data.nomineeIdSelector === "PAN") {
      triggerToast("Pan is invalid");
        return false;
    };

    return true;
  };

  const onSubmit = async (updatedNomineeDetails) => {
      const isNomineeMinor = isMinor(updatedNomineeDetails.dob);

    if (!validateFields(updatedNomineeDetails)){
        return; 
      }

      setIsLoading(true);
      const isPanId = updatedNomineeDetails?.nomineeIdSelector === 'PAN';

      try {
        const payload: Record<string, any> = {
          name: updatedNomineeDetails.name,
          relation: updatedNomineeDetails.relation,
          dob: dateFormat(updatedNomineeDetails.dob, "isoDate").split("-").reverse().join("/"),
          guardianName: isNomineeMinor
            ? updatedNomineeDetails?.guardian_name
            : undefined,
          guardianDob: isNomineeMinor
            ? dateFormat(updatedNomineeDetails?.guardian_dob, "isoDate").split("-").reverse().join("/") 
            : undefined,
            
          guardianPanNumber: isNomineeMinor
            ? updatedNomineeDetails?.guardian_pan_number?.toUpperCase()
            : undefined,
          panNumber: isPanId
            ? updatedNomineeDetails?.pan_number?.toUpperCase()
            : '',
          aadhaarNumber: !isPanId
            ? updatedNomineeDetails.aadhaar_number
            : '',
        };

      const res = await updateNomineeDetails(payload);
      if (res.status === 200) {
        updateStatus();
      }
    } catch (error) {
        handleApiError(error);
    } 
    finally{
      setIsLoading(false);
    }
  };

  return (
    <NomineeDetails
      isLoading={isLoading}
      isDisabled={false}
      onSubmit={onSubmit}
    />
  );

};

export default NomineeDetailsContainer;
