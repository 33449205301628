const IMAGES = {
    profileCardSearch : "https://i.wlycdn.com/articles/profile-card-search.svg",
    infoIcon:"https://i.wlycdn.com/articles/info-icon-light-purple-ico.svg",
    playStore:"https://i.wlycdn.com/articles/play-store-with-theme-bg.svg",
    appStore:"https://i.wlycdn.com/articles/app-store-with-theme-bg.svg",
    wealthyLogo:"https://i.wlycdn.com/articles/wealthy-logo-light-purple.svg",
    mainColorWealthyLogo:"https://i.wlycdn.com/wealthy-home-page/wealthy-new-purple-logo.svg",
    infoIconThemeColor:"https://i.wlycdn.com/family-flow/crn-info-icon.svg",
    successIconImage:"https://i.wlycdn.com/wealthy-kyc/kyc-success-tick.svg",
    infoIconImage:"https://i.wlycdn.com/family-flow/crn-info-icon.svg",
    fileUploadImage:"https://i.wlycdn.com/wealthy-home-page/upload-cloud-icon.png",
    deleteImage:"https://i.wlycdn.com/wealthy-home-page/partner-close-button.svg",
    crossIcon:"https://i.wlycdn.com/articles/cross-icon.svg",
    phonePe:"https://i.wlycdn.com/articles/phone-logo-png.png",
    gpay:"https://i.wlycdn.com/articles/gpay-logo.png",
    paytm:"https://i.wlycdn.com/articles/paytm-logo-v2.svg",
    bharathPay:"https://i.wlycdn.com/articles/bharath_pay_icon_svg.svg",
    upiLogo:"https://i.wlycdn.com/articles/upi-logo-svg.svg",
    whatsapp:"https://i.wlycdn.com/articles/whatsapp-green-icon.svg",
    arrowHeadRight:"https://i.wlycdn.com/wealthy-home-page/partners-button-right-arrow.svg",
    closeImage:
        'https://i.wlycdn.com/articles/cross-cancel-black-filled-icon.svg',
    supportIcon: 'https://i.wlycdn.com/icons/pst-support-icon.png',
};

export default IMAGES;