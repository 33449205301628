import {
    generateRPDRequest
} from 'api/rest';
import styled from "styled-components";
import React, {
 useEffect,useRef,useState
} from 'react';
import RPD from './RPD';

import {
    Error,
    LoaderContainer, Spinner
} from "../commons";

import {
 handleApiError
} from 'helpers';

function RpdContainer({updateStatus}) {
    const [rpdDetails, setRPDDetails] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const rpdApiRef = useRef(null);


    useEffect(()=> {
        if (!rpdApiRef?.current){
            setIsLoading(true);
            generateRPDRequest().then((res) => {
                setRPDDetails(res.data.rpdDetails);
                rpdApiRef.current = true;
            }).catch(handleApiError).finally(() => {
                setIsLoading(false);
            });
        }
    },[]);

    if (isLoading) return <LoaderContainer className='h-100'>
        <Spinner
            color="blue"
            message="Preparing for bank verification..."
        />
    </LoaderContainer>;
    else if(!rpdDetails) return <ErrorContainer><Error /></ErrorContainer>;
    return <RPD updateStatus={updateStatus}  rpdDetails={rpdDetails} />;
}

export default RpdContainer;


const ErrorContainer = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding:0 16px;

    .error-image{
        height:300px;
        @media screen and (max-width:720px){
            height:200px;
        }
    }
`;
