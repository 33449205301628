/**
Author - Brijesh Pandey
Git - https://bitbucket.org/__brijesh/
**/

import React from "react";
import styled from "styled-components";

import {
  isMinor
} from "utils/dateUtils";

import {
  ActionButton
} from "screens/commons";
import MuiForm from "components/MuiForm";
import {
  getNomineeSchema
} from "./NomineeSchema";

export const NomineeForm = ({onSubmit, isLoading, isDisabled}) => {
  const fetchSchema = () => getNomineeSchema();

  return  <Container>
    <Title>Nominee Details</Title>
    <SubHeader>Add person as nominee for all investments</SubHeader>
  <MuiForm
    schema={fetchSchema()}
    onSubmit={onSubmit}
    fetchSchema={fetchSchema}
    buttons={{
      submit: (
        <ActionButton
          disabled={isDisabled}
          className="submit_btn font-weight-500"
          buttonText={"Add Nominee"}
          isLoading={isLoading}
        />
      ),
    }}
    cacheId={Math.random().toString()}
    className={'meta-form-full-height'}
    wrapperClassName={'meta-form-full-height'}
    title={''}
    sub_title={''}
    updateSchema={(result) => result}
    fns={{
      isMinor: isMinor,
    }}
  />
  </Container>;

};


const Container = styled.div`
  padding: 2.4rem 0;
  width: 90%;
  margin: 0 auto;
`;

const Title = styled.h1.attrs({
  className: 'primary-title'
})`
  margin: 0;
  padding: 0;
  letter-spacing: 0.1rem;
`;

const SubHeader = styled.p.attrs({
  className: 'description2'
})`
  margin: 1rem 0 0 0;
  padding: 0;
  color: #7E7E7E;
`;