import MuiForms from 'mui-forms';

import React from 'react';
import styled from 'styled-components';

export default function MuiForm(props) {
  return (
    <FormWrapper>
    <MuiForms
      config={{
        variant: 'standard',
      }}
      changeResponseMode={'form-data'}
      {...props}
    />
    </FormWrapper>
  );
}



const FormWrapper = styled.div`
  .stepper {
    margin: 2rem;
    opacity: 0;
  }

  .input-container {
    margin-bottom: 3.3rem;
  }

  .input-container label {
    font-size: 1.4rem;
    font-weight: 400;
    color: #7E7E7E;
  }

  .input-container label+div {
    font-size: 1.6rem;
    font-weight: 400;
    color: #000000;
  }

  .input-container input {
    border: none;
    background: transparent;
    border-bottom: 1px solid #DDDDDD;
    outline: none;
    padding-bottom: 0.5rem;
    color: #000000;
    font-size: 1.6rem;
    font-family: MavenPro;
    font-weight: 500;
    width: 100%;
    text-transform: uppercase;
  }

  .action-btn {
    padding: 2rem 0;
    background: #6725F4;
    border-radius: 52px;
    width: 100%;
    margin-top: 3rem;
    color: #ffffff;
    font-size: 1.6rem;
    font-family: MavenPro;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: scale 300ms;

    :hover {
      background-color: #6725F4;
      scale: 1.02;
    }
  }

  .prev-action-btn {
    padding: 2rem 0;
    background: transparent;
    color: #6725F4;
    border-radius: 52px;
    width: 100%;
    margin-top: 3rem;
    font-size: 1.6rem;
    font-family: MavenPro;
    font-weight: 500;
    display: flex;
    justify-content: left;
    align-items: center;

    :hover {
      background: transparent;
    }
  }
`;