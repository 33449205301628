/**
Author - Brijesh Pandey
Git - https://bitbucket.org/__brijesh/
**/

import getDeviceInfo from "./getDeviceInfo";

const checkIsNativeDevice = async () => {
  const deviceInfo = await getDeviceInfo();
  const deviceOS = deviceInfo.operatingSystem;
  const isNativeDevice = (deviceOS === "android" || deviceOS === "ios") ? true : false;
  return isNativeDevice;
};

export default checkIsNativeDevice;


export function detectMobileDevice() {
  const userAgent = navigator.userAgent.toLowerCase();

  const platform = navigator.platform.toLowerCase();

  const isIOS =
    ['iphone', 'ipad', 'ipod'].includes(platform) ||
    /iphone|ipad|ipod/.test(userAgent);

  const isAndroid =
    /android/.test(userAgent) ||
    platform === 'linux armv8l' ||
    platform === 'linux armv7l';


  const isMobile = isIOS ||
    isAndroid ||
    /mobile|tablet/.test(userAgent);

  return {
    isIOS,
    isAndroid,
    isMobile,
    userAgent,
    platform
  };
}
