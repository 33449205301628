/*
  Author : Ravikant Chaudhary
  Github: https://github.com/ravikantc17
*/

import {
  getKycStatus
} from "api";
import {
  handleApiError
} from "helpers";

const pollingUpdateStatus = (updateStatus, handleLoading, handleErrorState, timeInterval) => {
  const stage = sessionStorage.getItem("stage");

  const statusPoll = setInterval(async () => {
    try {
      const response = await getKycStatus();
      if (response.data.stage !== stage) {
        updateStatus();
        handleLoading();
        clearInterval(statusPoll);
      };
    } catch (error) {
      handleApiError(error);
      handleErrorState && handleErrorState(true);
      clearInterval(statusPoll);
    }
  }, timeInterval || 2500);
};

export default pollingUpdateStatus;
