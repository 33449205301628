/**
Author - Venkatesh 😁
Git - https://github.com/venkatesh1146
**/


import React, {
  useEffect, useState
} from "react";

import styled from "styled-components";

import MetaForm from "@manojadams/metaforms";
import PopupModal from "components/Popup";


const FATCAForm = ({
    formSchema,
    handleFormSubmit
}) => {
  const [showSupportPopup, setShowSupportPopup] = useState(false);
    useEffect(() => {
        const stepperContainer = document.querySelector('[class*="meta-form-stepper"]');
        if (!!stepperContainer) {
            stepperContainer.classList.add("stepper");
        }
    }, []);

    return (
        <Container>
            <Wrapper>
                <Title>{formSchema?.pageTitle ? formSchema.pageTitle  : "FATCA Details"}</Title>
                <SubHeader className="mb-5">{formSchema?.cardTitle ? formSchema.cardTitle : "Please fill in your FATCA details"}</SubHeader>
                <FormWrapper>
                    <MetaForm
                        schema={formSchema?.schema}
                        onSubmit={(data) => {
                          if (['US',"CA"].includes(data.fatca_citizenship)){
                            setShowSupportPopup(true);
                            return;
                          }
                            handleFormSubmit(data);
                        }}
                    />
                </FormWrapper>
                <ExtraSpace />
            </Wrapper>
        <PopupModal
          close={()=> setShowSupportPopup(false)}
          shouldShow={showSupportPopup}
          showSupportLinks
          modalHeader={
            <Heading className="description1 primary-title">
              Attention !
            </Heading>
          }
        >
          <Message className="description2">
              We currently do not support online account creation for clients from
              the USA or Canada. Please reach out to your Wealthy partner for
              assistance with opening an account offline
          </Message>
          </PopupModal>
        </Container>
    );
};

const Heading = styled.h3`
  margin:0 0 16px 0;
`;


const Message = styled.p`
  margin:0 0 16px 0;
  f
`;

const Container = styled.div`
  height: 100vh;
  width: 100%;
  scroll-behavior: scroll;
  position: relative;
  overflow-y: scroll;
  display:flex;
  flex-direction:column;



  @media (max-width: 768px) {
    background: white;
  }
  .container, .basic-form{
    height:100%;
  }
  form > div {
    height:100%;
    .row {
        height: 100%;
    }
  }
  .footer {
    margin-top: 4rem;
  }
`;

const Wrapper = styled.div`
  padding: 2.4rem 0;
  width: 90%;
  margin: 0 auto;
  flex-grow:1;
  display:flex;
  flex-direction:column;

  .meta-form-control-fatca_tax_no {
    margin-bottom:-1rem !important;
  }

  .meta-form-control-fatca_country_residency,
  .meta-form-control-fatca_birth_country, 
  .meta-form-control-fatca_citizenship,
  .meta-form-control-fatca_tax_exempt_reason,
  .input-container {
    font-family: MavenPro !important;
    height:42px !important;
    margin-top:0.5rem;
    label {
      font-size:14px !important; 
    }
    input {
      height:100% !important;
      font-size:16px !important; 
      border:none !important;
    }
  }
`;

const ExtraSpace = styled.div`

  height: 250px;
  margin: 10rem 0;
  opacity: 0;
  @media screen and (max-width: 768px) {
    height:0;
  }
`;


const Title = styled.h1.attrs({
    className: 'primary-title'
})`
  margin: 0;
  padding: 0;
  letter-spacing: 0.1rem;
`;

const SubHeader = styled.p.attrs({
    className: 'description2'
})`
  margin: 1rem 0 0 0;
  padding: 0;
  color: #7E7E7E;
`;

const FormWrapper = styled.div`
    flex-grow:1;
  .stepper {
    margin: 2rem;
    opacity: 0;
  }

  .MuiAutocomplete-root, .input-container {
    margin-bottom: 0.8rem;
  }

  .MuiAutocomplete-root label, .input-container label {
    font-size: 1.2rem;
    font-weight: 400;
    color: #7E7E7E;
  }

  .MuiAutocomplete-root label+div ,.input-container label+div {
    font-size: 1.2rem;
    font-weight: 400;
    color: #000000;
  }

  .MuiAutocomplete-root input, .input-container input {
    border: none;
    background: transparent;
    border-bottom: 1px solid #DDDDDD;
    outline: none;
    padding-bottom: 0.5rem;
    color: #000000;
    font-size: 1.4rem;
    font-family: MavenPro;
    font-weight: 500;
    width: 100%;
    text-transform: uppercase;
  }

  .action-btn {
    padding: 1rem 0;
    background: #6725F4;
    border-radius: 52px;
    width: 100%;
    margin-top: 3rem;
    color: #ffffff;
    font-size: 1.6rem;
    font-family: MavenPro;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: scale 300ms;
    margin-top:auto;

    :hover {
      background-color: #6725F4;
      scale: 1.02;
    }
  }

  .prev-action-btn {
    padding: 2rem 0;
    background: transparent;
    color: #6725F4;
    border-radius: 52px;
    width: 100%;
    margin-top: 3rem;
    font-family: MavenPro;
    font-weight: 500;
    display: flex;
    justify-content: left;
    align-items: center;

    :hover {
      background: transparent;
    }
  }
`;

export default FATCAForm;
